// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "typeface-nunito"
import "typeface-alegreya"

export const onRouteUpdate = ({ location }) => {
  // if (location.pathname === "/" || location.pathname === "") {
  //   var navCurrent = document.getElementsByClassName("nav-home")[0]
  //   navCurrent.classList.add("nav-current")
  //   navCurrent.firstElementChild.style.opacity = 1
  // }
  // if (location.pathname === "/about/" || location.pathname === "/about")
  //   var navCurrent = document.getElementsByClassName("nav-about")[0]
  // navCurrent.classList.add("nav-current")
  // navCurrent.firstElementChild.style.opacity = 1
  if (
    location.pathname === "/" ||
    location.pathname === "" ||
    location.pathname.startsWith("/tags/")
  ) {
    var style = document.getElementById("style")
    var zoomInButton = document.getElementById("zoom-in")
    var zoomOutButton = document.getElementById("zoom-out")
    var postFeed = document.getElementById("post-feed")
    var slider = document.getElementById("slider")
    slider.value = 3

    var tag = "all"

    function setCSS() {
      let CSS = `#show-${tag} {
				color: #cf3177 !important;
        border: 1px solid #e6a1bf !important;
				background: #f4e7ed;
			}`

      if (tag !== "all")
        CSS += `.post-card:not([class*="${tag}"]){display: none;}`
      if (slider.value >= 4) CSS += ".post-card-title {font-size: 1rem;}"
      style.innerHTML = CSS
    }

    zoomOutButton.addEventListener(
      "click",
      function() {
        if (slider.value < 8) {
          slider.value++

          postFeed.style.columns = slider.value
          zoomInButton.style.color = "#cf3177"
        }
        setCSS()
        if (slider.value === 8) zoomOutButton.style.color = "#ddd"
      },
      false
    )

    zoomInButton.addEventListener(
      "click",
      function() {
        if (slider.value > 1) {
          slider.value--

          postFeed.style.columns = slider.value
          zoomOutButton.style.color = "#cf3177"
        }
        setCSS()
        if (slider.value === 1) zoomInButton.style.color = "#ddd"
      },
      false
    )

    slider.addEventListener(
      "change",
      function() {
        postFeed.style.columns = slider.value
        zoomInButton.style.color = "#cf3177"
        zoomOutButton.style.color = "#cf3177"
        if (slider.value === 1) zoomInButton.style.color = "#ddd"
        if (slider.value === 8) zoomOutButton.style.color = "#ddd"
        setCSS()
      },
      false
    )

    var showAll = document.getElementById("show-all")
    var showVideo = document.getElementById("show-video")
    var showDesign = document.getElementById("show-design")
    var showMusic = document.getElementById("show-music")

    showAll.addEventListener(
      "click",
      function() {
        tag = "all"
        setCSS()
      },
      false
    )

    showVideo.addEventListener(
      "click",
      function() {
        tag = "video"
        setCSS()
      },
      false
    )

    showDesign.addEventListener(
      "click",
      function() {
        tag = "design"
        setCSS()
      },
      false
    )

    showMusic.addEventListener(
      "click",
      function() {
        tag = "music"
        setCSS()
      },
      false
    )
  }
}
